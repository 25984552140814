import { $host } from "./index";

export const createBrand = async (brand) => {
    const { data } = await $host.post('api/brand', brand);
    return data;
};

export const fetchBrands = async () => {
    try {
        const response = await $host.get('api/brand');
        
        if (!response.data?.brands) {
            throw new Error("Некорректный ответ сервера");
        }
        
        return response.data.brands;
    } catch (error) {
        console.error("Ошибка получения брендов:", error);
        return [];
    }
};

// Остальные методы
export const createType = async (type) => {
    const { data } = await $host.post('api/type', type);
    return data;
};

export const fetchTypes = async () => {
    const { data } = await $host.get('api/type');
    return data?.types || [];
};

export const createDevice = async (device) => {
    const { data } = await $host.post('api/device', device);
    return data;
};

export const fetchDevices = async (typeId, brandId, page, limit = 9) => {
    const { data } = await $host.get('api/device', {
        params: { typeId, brandId, page, limit }
    });
    return data;
};

export const fetchOneDevice = async (id) => {
    const { data } = await $host.get(`api/device/${id}`);
    return data;
};