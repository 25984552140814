import { $authHost, $host } from "./index";
import { jwtDecode } from "jwt-decode";

export const registration = async (email, password) => {
  const { data } = await $host.post('api/user/registration', { 
    email, 
    password, 
    role: 'ADMIN' 
  });
  localStorage.setItem('token', data.token);
  return jwtDecode(data.token);
};

export const login = async (email, password) => {
  const { data } = await $host.post('api/user/login', { email, password });
  localStorage.setItem('token', data.token);
  return jwtDecode(data.token);
};

export const check = async () => {
  // Важно: используем обычный $host и ручную передачу токена
  const { data } = await $host.get('api/auth/check', {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`
    }
  });
  
  // Обновляем токен при успешной проверке
  localStorage.setItem('token', data.token);
  return jwtDecode(data.token);
};