import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Context } from "..";
import { Card, Row, Col, Spinner, Alert } from "react-bootstrap";
import { fetchBrands } from "../http/deviceAPI";

const BrandBar = observer(() => {
    const { device } = useContext(Context);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const loadData = async () => {
            try {
                const brandsData = await fetchBrands();
                
                if (!brandsData || !Array.isArray(brandsData)) {
                    throw new Error("Неверный формат данных о брендах");
                }
                
                device.setBrands(brandsData);
            } catch (err) {
                setError(err.message);
                console.error("Ошибка загрузки брендов:", err);
            } finally {
                setLoading(false);
            }
        };

        loadData();
    }, [device]);

    const handleBrandClick = (brand) => {
        device.setSelectedBrand(
            device.selectedBrand?.id === brand?.id ? null : brand
        );
    };

    if (loading) {
        return (
            <Row className="justify-content-center mt-3">
                <Spinner animation="border" variant="primary" />
            </Row>
        );
    }

    if (error) {
        return (
            <Row className="justify-content-center mt-3">
                <Alert variant="danger">{error}</Alert>
            </Row>
        );
    }

    return (
        <Row className="d-flex flex-nowrap overflow-auto py-3">
            {/* Все бренды */}
            <Col xs="auto" className="pe-2">
                <Card
                    style={{
                        cursor: "pointer",
                        minWidth: "140px",
                        border: "2px solid",
                        borderColor: !device.selectedBrand ? "#007bff" : "#dee2e6",
                        backgroundColor: !device.selectedBrand ? "#e7f1ff" : "white"
                    }}
                    className="p-3 text-center shadow-sm"
                    onClick={() => handleBrandClick(null)}
                >
                    Все бренды
                </Card>
            </Col>

            {/* Список брендов */}
            {device.brands.map(brand => (
                <Col xs="auto" className="pe-2" key={brand.id}>
                    <Card
                        style={{
                            cursor: "pointer",
                            minWidth: "140px",
                            border: "2px solid",
                            borderColor: device.selectedBrand?.id === brand.id 
                                ? "#007bff" 
                                : "#dee2e6",
                            backgroundColor: device.selectedBrand?.id === brand.id 
                                ? "#e7f1ff" 
                                : "white"
                        }}
                        className="p-3 text-center shadow-sm"
                        onClick={() => handleBrandClick(brand)}
                    >
                        {brand.name}
                    </Card>
                </Col>
            ))}
        </Row>
    );
});

export default BrandBar;